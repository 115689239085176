@import '@assets/components_base';

.root {
  padding: var(--standard-margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  z-index: 0;
  min-height: 100vh;
}